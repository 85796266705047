import { useEffect } from "react";
import { useCookies } from "react-cookie";

import { FormUrl } from "@/enums/FormUrl";

export function useConsentGiven() {
  const cookieName = process.env.NEXT_PUBLIC_COOKIE_NAME ?? "vp-cookie-consent";
  const [cookies, _setCookie, removeCookie] = useCookies([cookieName]);
  const isConsentGiven = cookies[cookieName] === "true";

  useEffect(() => {
    if (!isConsentGiven) {
      Object.keys(cookies).forEach((cookieName) => {
        if (
          cookieName.startsWith("_ga") ||
          cookieName.startsWith("_fbp") ||
          cookieName.startsWith("_gcl")
        ) {
          removeCookie(cookieName, { path: "/" });
        }
      });
    }
  }, [isConsentGiven, cookies, removeCookie]);

  const pushFormDataLayer = (formType: FormUrl, data: any) => {
    if (isConsentGiven) {
      window.dataLayer?.push({
        event: "formSubmitted",
        formType,
        data,
      });
    }
  };

  return { isConsentGiven, pushFormDataLayer };
}
